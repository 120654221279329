import { Navigate } from "react-router-dom";
import { Dashboard } from "../pages/dashboard";
import { ProtectedPage } from "./protected-page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHospital } from "@fortawesome/free-solid-svg-icons";
import ArchivedPropertiesPage from "../pages/archived-properties";

export interface IRoute {
  name: string;
  path: string;
  icon: React.ReactElement;
}

export const navigation: IRoute[] = [
  {
    name: "Your properties",
    icon: <FontAwesomeIcon icon={faHospital} />,
    path: "/properties",
  },
  {
    name: "Archived properties",
    icon: <FontAwesomeIcon icon={faHospital} />,
    path: "/archived-properties",
  }
];

export const routes = [
  {
    name: "Properties",
    path: "/properties",
    component: (
      <ProtectedPage>
        <Dashboard />
      </ProtectedPage>
    ),
  },
  {
    name: "Archived properties",
    path: "/archived-properties",
    component: (
      <ProtectedPage>
        <ArchivedPropertiesPage />
      </ProtectedPage>
    ),
  },

  // Redirect all
  {
    name: "Redirect",
    path: "*",
    component: (
      <ProtectedPage>
        <Navigate to="/properties" />
      </ProtectedPage>
    ),
  },
];
