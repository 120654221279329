import React from "react";
import { IContract } from "../services/contracts.service";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  gridClasses,
} from "@mui/x-data-grid";
import styled from "@emotion/styled";
import { alpha, Box, Button, Typography } from "@mui/material";
import { DateTime } from "luxon";
import { ValidChip } from "../atoms/valid";
import { InspectionDue } from "../atoms/inspection-due";
import { useTheme } from "@emotion/react";
import { ExpiredChip } from "../atoms/invalid";
import { ContractDialog } from "./contract-dialog";
import { SelectedContext } from "../contexts/select-contract";

interface ContractDataGridProps {
  contracts: IContract[];
  archivedPage: boolean;
}

// reference, name, lastInspection, status, customer, primary contact, view.

const ODD_OPACITY = 0.2;

const StripedDataGrid = styled(DataGrid)(({ theme }: { theme: any }) => ({
  border: "1px solid #edf6f9",
  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
  [`& .${gridClasses.row}`]: {
    border: "none",
    "&.Mui-selected": {
      "&:hover, &.Mui-hovered": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity
        ),
      },
    },
  },
  [`& .${gridClasses.cell}`]: {
    border: "none",
  },
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "#edf2f4",
  },
  "&  .MuiDataGrid-iconSeparator": {
    visibility: "hidden",
  },
}));

const columns: GridColDef[] = [
  {
    field: "name",
    headerName: "Name",
    flex: 1,
    sortable: true,
    minWidth: 300,
    renderCell: (params: GridRenderCellParams) => {
      // get the reference from the row data
      const reference = params.row.reference;
      const name = params.value;

      return (
        <div>
          <Typography variant="body1" fontWeight={500}>
            {name}
          </Typography>
          <Typography variant="caption">{reference}</Typography>
        </div>
      );
    },
  },
  {
    field: "lastInspection",
    headerName: "Last Inspection",
    flex: 1,
    maxWidth: 120,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      const lastInspection = params.value;

      const date = DateTime.fromISO(lastInspection);
      const formattedDate = date.toFormat("dd LLL yyyy");

      if (formattedDate === "Invalid DateTime") {
        return "";
      }

      const daysAgo = date.diff(DateTime.now(), ["days"]).toObject();
      const days = Math.abs(Math.floor(daysAgo.days as number));

      return (
        <div>
          <Typography variant="body1" fontWeight={500}>
            {formattedDate}
          </Typography>
          <Typography variant="caption">{days} days ago</Typography>
        </div>
      );
    },
  },
  {
    field: "status",
    headerName: "Status",
    flex: 1,
    maxWidth: 150,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      const status = params.value;
      switch (status) {
        case "Valid":
          return <ValidChip />;
        case "Inspection Due":
          return <InspectionDue />;
        case "Invalid":
          return <ExpiredChip />;
        default:
          return status;
      }
    },
  },
  {
    field: "customer",
    headerName: "Customer",
    flex: 1,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      const customer = params.value;
      return (
        <div>
          <Typography variant="body1" fontWeight={500}>
            {customer.organisationName}
          </Typography>
          <Typography variant="caption">
            {customer.contracts.length} contracts
          </Typography>
        </div>
      );
    },
  },
  {
    field: "primaryContact",
    headerName: "Primary Contact",
    flex: 1,
    maxWidth: 200,
    sortable: true,
    renderCell: (params: GridRenderCellParams) => {
      const primaryContact = params.value;
      return (
        <div>
          <Typography variant="body1" fontWeight={500}>
            {primaryContact && primaryContact.name_first}{" "}
            {primaryContact && primaryContact.name_last}
          </Typography>
          <Typography variant="caption">
            {primaryContact && primaryContact.email}
          </Typography>
        </div>
      );
    },
  },
  {
    field: "view",
    headerName: "",
    flex: 1,
    maxWidth: 100,
    sortable: false,
    renderCell: (params) => <ViewContractButton {...params} />,
  },
];

const ViewContractButton = (params: GridRenderCellParams) => {
  const { setSelectedContract, setOpen } = React.useContext(SelectedContext);

  const onClick = () => {
    setSelectedContract(params.row._id);
    setOpen(true);
  };

  return (
    <Box
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Button onClick={onClick}>View</Button>
    </Box>
  );
};

const Header = () => {
  return (
    <Box display="flex" justifyContent="space-between" sx={{ padding: "20px" }}>
      <Typography variant="h5" fontWeight={500}>
        Your properties
      </Typography>
    </Box>
  );
};

const HeaderArchived = () => {
  return (
    <Box display="flex" justifyContent="space-between" sx={{ padding: "20px" }}>
      <Typography variant="h5" fontWeight={500}>
        Your archived properties
      </Typography>
    </Box>
  );
};

export const ContractDataGrid = ({
  contracts,
  archivedPage,
}: ContractDataGridProps) => {
  const { setSelectedContract, open, setOpen } =
    React.useContext(SelectedContext);

  const onClose = () => {
    setSelectedContract(null);
    setOpen(false);
  };

  const header = archivedPage ? HeaderArchived : Header;

  const theme = useTheme();

  return (
    <>
      <StripedDataGrid
        rows={contracts}
        columns={columns}
        components={{
          Header: header,
        }}
        pageSize={20}
        rowHeight={75}
        rowsPerPageOptions={[5, 10, 20]}
        getRowId={(row) => row._id}
        checkboxSelection
        disableSelectionOnClick
        autoHeight
        autoPageSize
        theme={theme}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
        }
      />

      <ContractDialog open={open} onClose={onClose} />
    </>
  );
};
