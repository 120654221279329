import React from "react";
import { IContract } from "../services/contracts.service";

export const SelectedContext = React.createContext<any>({
  setSelectedContract: () => {},
  setOpen: () => {},
  open: false,
  selectedContract: "",
});

export const SelectedProvider = ({ children }: any) => {
  const [selectedContract, setSelectedContract] = React.useState<IContract>();
  const [open, setOpen] = React.useState(false);

  const value = {
    selectedContract,
    setSelectedContract,
    open,
    setOpen,
  };

  return (
    <SelectedContext.Provider value={value}>
      {children}
    </SelectedContext.Provider>
  );
};
