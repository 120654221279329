import React from "react";
import styled from "@emotion/styled";

const ArchivedBarWrapper = styled.div({
  height: "50px",
  backgroundColor: "#ecf0f1",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bold",
  color: "#c0392b",
  padding: 20,
});

export default function ArchivedBar() {
  return <ArchivedBarWrapper>This property is archived</ArchivedBarWrapper>;
}
