import { AxiosInstance } from "axios";
import { toast } from "react-toastify";

export interface IContract {
  archived: boolean;
  openRequests?: any;
  lastInspection: string;
  _id: string;
  name: string;
  customer: any;
  reference: string;
  contact: any;
  primaryContact: any;
  safetyInspections: IInspection[];
  status: string;
  lastReminderSent: Date;
  sendInspectionAlerts: boolean;
  inspectionAlertRecipients: string[];
  trackTemplates: string[];
  blueprint: any; // Blueprint;
  daysToExpiry?: any;
  alertRecipients: string[];
  createdAt: string;
  updatedAt: Date;
}

export enum AuditType {
  AUDIT = 'AUDIT',
  INSTALLATION = 'INSTALLATION',
  PRODUCT_TESTING = 'PRODUCT_TESTING',
}

export interface IInspection {
  _id?: string 
  date: string;
  contract: any;
  reference: string;
  complete: boolean;
  auditType: AuditType;
  areas: any[];
  auditor: string;
  loggingRate: number;
  loadTestRate: number;
  gliderCountRate: number;
  additionalLabourRate: number;
  additionalLabourTime: number;
  additionalPartPrice: number;
  reportUrl: string;
  reports: IReport[];
  internalReference: string;
  internalNotes: string[];
  notes: string[];
  adhoc: boolean;
  fixedFee: number;
  invoice: any[];
  enforceAssetIDOnTracks: boolean;
  processedReports?: IReport[];
}

export interface IReport {
  url: string;
  name: string;
  authorName: string;
  authorEmail: string;
  version: number;
  sentToCustomer: boolean;
}


export class ContractsService {
  constructor(private api: AxiosInstance) {}

  /**
   * Get all contracts
   * @returns
   */
  public getContracts() {
    return new Promise((resolve: (contracts: IContract[]) => any, reject) => {
      this.api
        .get("/contracts")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          toast.error("Something went wrong, please try again later.");
          reject(error);
        });
    });
  }

  /**
   * Get archived contracts
   * @returns {Promise<IContract[]>}
   */
  public getArchivedContracts(): Promise<IContract[]> {
    return new Promise((resolve: (contracts: IContract[]) => any, reject) => {
      this.api
        .get("/contracts/archived")
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          toast.error("Something went wrong, please try again later.");
          reject(error);
        });
    });
  }

  /**
   * Get a contract by id.
   * @param id
   * @returns
   */
  public getContract(id: string) {
    return new Promise((resolve: (contract: IContract) => any, reject) => {
      this.api
        .get(`/contracts/${id}`)
        .then((response) => {
          resolve(response.data.data);
        })
        .catch((error) => {
          toast.error("Something went wrong, please try again later.");
          reject(error);
        });
    });
  }
}
