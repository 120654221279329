import { Chip } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";

export const InspectionDue = () => {
  return (
    <Chip
      sx={{
        display: "flex",
        alignItems: "center",
        width: "70%",
        justifyContent: "space-evenly",
      }}
      icon={<FontAwesomeIcon icon={faWarning} size="lg" />}
      label="Audit Due"
      color="warning"
    />
  );
};
