const env = process.env.NODE_ENV || process.env.ENV;

export const config = {
  api: {
    baseUrl: {
      dev: "http://localhost:3020",
      prod: "https://safer-track-customerapi.azurewebsites.net",
      getBaseUrl() {
        switch (env) {
          case "development":
            return this.dev;
          case "production":
            return this.prod;
          default:
            return this.dev;
        }
      },
    },
  },
};
