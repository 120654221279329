import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "./navigation/navigation";
import Helmet from "react-helmet";
import { AuthProvider } from "./contexts/auth.context";
import { LoginPage } from "./pages/login";
import Layout from "./layout/layout";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/theme";
import { ForgotPassword } from "./pages/forgot-password";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <Helmet>
            <title>Safer Track</title>
          </Helmet>
          <Layout>
            <Routes>
              <Route path="/auth/login" element={<LoginPage />} />

              <Route path="/forgot-password" element={<ForgotPassword/>}/>

              {routes.map((item) => {
                return (
                  <Route
                    key={item.name}
                    path={item.path}
                    element={item.component}
                  />
                );
              })}
            </Routes>
          </Layout>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
