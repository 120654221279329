import React, { useContext, useEffect, useState } from "react";
import { ContractsService, IContract } from "../services/contracts.service";
import { AuthContext } from "../contexts/auth.context";
import { ContractDataGrid } from "../organisms/contract-data-grid";
import { SelectedProvider } from "../contexts/select-contract";
import { Container } from "../atoms/container";

export const Dashboard = () => {
  const { api } = useContext(AuthContext);

  const [contracts, setContracts] = useState<IContract[]>([]);

  useEffect(() => {
    const contractsService = new ContractsService(api);

    contractsService
      .getContracts()
      .then((contracts) => setContracts(contracts));
  }, [api]);

  return (
    <Container>
      <div style={{ marginBottom: 20 }}>
        <h1>Welcome to Safer-Track</h1>
        <p>
          Welcome to Safer-Track, the ultimate anti-ligature compliance
          management solution. With our app, you can easily track and maintain
          Anti-Ligature audit compliance for your properties, giving you peace
          of mind and ensuring the safety of your patients and staff. Our
          user-friendly interface allows you to access and manage your
          properties with ease, and our dedicated support team is available to
          assist you every step of the way.
        </p>
      </div>

      <SelectedProvider>
        <ContractDataGrid contracts={contracts} archivedPage={false}/>
      </SelectedProvider>
    </Container>
  );
};
