import {
  Dialog,
  DialogTitle,
  CircularProgress,
  Box,
  DialogContent,
  Typography,
  Grid,
  TableContainer,
  Paper,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  TableBody,
  Button,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/auth.context";
import { SelectedContext } from "../contexts/select-contract";
import {
  AuditType,
  ContractsService,
  IContract,
} from "../services/contracts.service";
import { DateTime } from "luxon";
import { ValidChip } from "../atoms/valid";
import { InspectionDue } from "../atoms/inspection-due";
import { ExpiredChip } from "../atoms/invalid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faDownload } from "@fortawesome/free-solid-svg-icons";
import styled from "@emotion/styled";
import ArchivedBar from "../atoms/archived-bar";

interface ContractDialogProperties {
  open: boolean;
  onClose: () => void;
}

const Spacer = styled.div`
  flex: 1 1 auto;
`;

interface AuditTableRow {
  salesReference: string;
  auditDate: string;
  auditType: AuditType;
  auditor: string;
  report: any;
}

const createTableRow = (
  salesReference: string,
  auditDate: string,
  auditType: AuditType,
  auditor: string,
  report: any
): AuditTableRow => {
  return { salesReference, auditDate, auditType, auditor, report };
};

const Loading = () => {
  return (
    <Box sx={{ padding: 10, display: "flex", justifyContent: "center" }}>
      <CircularProgress />
    </Box>
  );
};

const StatusChip = ({ status }: { status: string }) => {
  if (status === "Valid") {
    return <ValidChip />;
  }

  if (status === "Inspection Due") {
    return <InspectionDue />;
  }

  if (status === "Invalid") {
    return <ExpiredChip />;
  }

  return <div></div>;
};

const TypeChip = ({ type }: { type: AuditType}) => {
  switch (type) {
    case AuditType.AUDIT:
      return <div>Audit</div>
    case AuditType.INSTALLATION:
      return <div>Installation</div>
    case AuditType.PRODUCT_TESTING:
      return <div>Product Testing</div>
    default:
      return <div>Unknown</div>
  }
}

export const ContractDialog = (props: ContractDialogProperties) => {
  const { onClose, open } = props;

  const [loading, setLoading] = React.useState(true);
  const { api } = useContext(AuthContext);
  const { selectedContract } = useContext(SelectedContext);

  const [contract, setContract] = React.useState<IContract>();
  const [rows, setRows] = React.useState<AuditTableRow[]>([]);

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const contractsService = new ContractsService(api);

    if (selectedContract) {
      contractsService
        .getContract(selectedContract)
        .then((contract: IContract) => {
          setLoading(false);
          setContract(contract);

          const rows: AuditTableRow[] = contract.safetyInspections.map(
            (inspection) => {
              // find the latest report
              const sentReports = inspection.reports.filter(
                (x: any) => x.sentToCustomer === true
              );
              const latestReport = sentReports.sort((a: any, b: any) => {
                return (
                  new Date(a.updatedAt).getTime() -
                  new Date(b.updatedAt).getTime()
                );
              })[0];

              return createTableRow(
                inspection.internalReference,
                inspection.date,
                inspection.auditType,
                inspection.auditor,
                latestReport
              );
            }
          );

          setRows(rows);
        })
        .catch((error) => {});
    }
  }, [selectedContract, api]);

  const downloadReport = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true}>
      {loading && <Loading />}
      {contract && (
        <>
          {contract.archived && <ArchivedBar />}
          <Box>
            <DialogTitle variant="h4" display="flex">
              {contract.name}

              <Spacer />

              <IconButton onClick={handleClose}>
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography variant="body1" fontWeight={600}>
                    Created At
                  </Typography>
                  <Typography variant="body1">
                    {DateTime.fromISO(contract.createdAt).toFormat(
                      "dd/MM/yyyy"
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" fontWeight={600}>
                    Last Inspection
                  </Typography>
                  <Typography variant="body1">
                    {contract.lastInspection &&
                      DateTime.fromISO(contract.lastInspection).toFormat(
                        "dd/MM/yyyy"
                      )}

                    {!contract.lastInspection && "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    sx={{ marginBottom: "10px" }}
                  >
                    Status
                  </Typography>
                  <StatusChip status={contract.status} />
                  {!contract.status && "-"}
                </Grid>
              </Grid>

              <Grid container spacing={3} sx={{ marginTop: "10px" }}>
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Reference</TableCell>
                          <TableCell align="left">Inspection Date</TableCell>
                          <TableCell align="left">Type</TableCell>
                          <TableCell align="left">Auditor</TableCell>
                          <TableCell align="right">Report</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, i) => (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left">
                              {row.salesReference}
                            </TableCell>
                            <TableCell align="left">
                              {DateTime.fromISO(row.auditDate).toFormat(
                                "dd/MM/yyyy"
                              )}
                            </TableCell>
                            <TableCell align="left"><TypeChip type={row.auditType}/></TableCell>
                            <TableCell align="left">{row.auditor}</TableCell>
                            <TableCell align="right">
                              {row.report && (
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  sx={{ textTransform: "none" }}
                                  startIcon={
                                    <FontAwesomeIcon
                                      size="sm"
                                      icon={faDownload}
                                    />
                                  }
                                  onClick={() => downloadReport(row.report.url)}
                                >
                                  Report
                                </Button>
                              )}

                              {!row.report && (
                                <Button
                                  disabled={true}
                                  variant="outlined"
                                  color="secondary"
                                >
                                  Not available
                                </Button>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </DialogContent>
          </Box>
        </>
      )}
    </Dialog>
  );
};
