import { createTheme } from "@mui/material";
import { orange } from "@mui/material/colors";

export const Colors = {
  primary: "#E67E22",
  secondary: "#00285C",
  tertiary: "#40739E",
  subtle: "#95A5A6",
};

export const theme = createTheme({
  palette: {
    primary: {
      main: Colors.secondary,
    },
    secondary: {
      main: Colors.primary,
    },
  },
  status: {
    danger: orange[500],
  },
  components: {
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: Colors.primary,
            fontWeight: 800,
            borderLeft: `5px solid ${Colors.primary}`,
            backgroundColor: "#F5F5F5",
          },
        },
      },
    },
  },
});

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}
