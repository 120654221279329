import { createContext, useEffect, useState } from "react";
import axios, { AxiosInstance } from "axios";
import { useNavigate } from "react-router-dom";
import { config } from "../config/config";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { ResetPasswordDialog } from "../organisms/update-password-dialog";

export interface UserData {
  firstName: string;
  lastName: string;
  customers: string[];
  email: string;
}

interface ITokenData {
  details: { firstName: string; lastName: string; customers: any[] };
  firstLogin: boolean;
  email: string;
  username: string;
}

interface IAuthContext {
  api: AxiosInstance;
  isLoggedIn: boolean;
  login: (username: string, password: string) => Promise<any>;
  loading: boolean;
  setLoading: (val: boolean) => void;
  logout: () => void;
  userData: UserData | null;
  rpOpen: boolean;
  setRpOpen: (val: boolean) => void;
}

interface AuthProviderProps {
  children: any;
}

export const AuthContext = createContext<IAuthContext>({
  api: axios.create(),
  isLoggedIn: false,
  login: () => new Promise(() => {}),
  loading: true,
  setLoading: (val) => {},
  logout: () => {},
  userData: null,
  rpOpen: false,
  setRpOpen: (val) => {},
});

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const navigate = useNavigate();

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [rpOpen, setRpOpen] = useState(false);

  const handleRpDialogClose = () => {
    setRpOpen(false);
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      const decoded = jwt_decode(token);
      if (decoded) {
        setUserData({
          firstName: (decoded as any).details.firstName,
          lastName: (decoded as any).details.lastName,
          customers: (decoded as any).details.customers,
          email: (decoded as any).email,
        });
      }
    }
  }, []);

  // set isLoggedIn at load time if token is saved in local storage
  useEffect(() => {
    const token = localStorage.getItem("access_token");
    if (token) {
      setIsLoggedIn(true);

      // Set the user data
      const decoded = jwt_decode(token);
      if (decoded) {
        setUserData({
          firstName: (decoded as any).details.firstName,
          lastName: (decoded as any).details.lastName,
          customers: (decoded as any).details.customers,
          email: (decoded as any).email,
        });
      }
    }
    setLoading(false);
  }, []);

  const api = axios.create({
    baseURL: config.api.baseUrl.getBaseUrl(),
    headers: {
      "Content-Type": "application/json",
    },
  });

  /**
   * Log in handler.
   * @param username
   * @param password
   */
  const login = async (email: string, password: string): Promise<any> => {
    return new Promise(async (resolve, reject) => {
      setLoading(true);
      axios
        .post(
          `${config.api.baseUrl.getBaseUrl()}/auth/login`,
          { email, password },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          localStorage.setItem("access_token", response.data.data.access_token);
          localStorage.setItem(
            "refresh_token",
            response.data.data.refresh_token
          );

          const decoded: ITokenData = jwt_decode(
            response.data.data.access_token
          );

          if (decoded) {
            setUserData({
              firstName: decoded.details.firstName,
              lastName: decoded.details.lastName,
              customers: decoded.details.customers,
              email: decoded.email,
            });

            if (decoded.firstLogin) {
              setRpOpen(true);
            }
          }

          setIsLoggedIn(true);
          setLoading(false);
          return resolve(true);
        })
        .catch((err) => {
          setIsLoggedIn(false);
          if (err.response.status === 401) {
            return toast.warn("Username or password incorrect.", {
              autoClose: 5000,
              pauseOnHover: true,
            });
          }
          setLoading(false);
          toast.warn("Something went wrong.");
          reject("error");
        });
    });
  };

  const logout = async () => {
    localStorage.clear();
    setIsLoggedIn(false);
    return navigate("/auth/login");
  };

  /**
   * Function for getting a new access token
   * with the refresh token.
   * @returns
   */
  const refresh = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        // get the refresh token from local storage
        const refreshToken = localStorage.getItem("refresh_token");

        // Send the refresh token to the API to get a new access token
        const response = await axios.post(
          `${config.api.baseUrl.getBaseUrl()}/auth/refresh`,
          {
            refreshToken,
          }
        );

        // Get the new access token from the response
        const accessToken = response.data.data.access_token;
        const newRefreshToken = response.data.data.refresh_token;

        // Save the new access token to local storage
        localStorage.setItem("access_token", accessToken);
        localStorage.setItem("refresh_token", newRefreshToken);

        const decoded = jwt_decode(response.data.data.access_token);

        if (decoded) {
          setUserData({
            firstName: (decoded as any).details.firstName,
            lastName: (decoded as any).details.lastName,
            customers: (decoded as any).details.customers,
            email: (decoded as any).email,
          });
        }

        resolve(accessToken);
      } catch (err) {
        return reject(err);
      }
    });
  };

  // request interceptor
  // get access token from local storage and send request.
  api.interceptors.request.use((config) => {
    if (config && config.headers)
      config.headers.Authorization = `Bearer ${localStorage.getItem(
        "access_token"
      )}`;
    return config;
  });

  // response interceptor
  // refreshes token if unauthorized.
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const status = error.response ? error.response.status : null;
      const originalRequest = error.config;
      if (status === 401) {
        // refresh token
        try {
          const newAccessToken = await refresh();
          // retry the request
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          return axios(originalRequest);
        } catch (err) {
          setIsLoggedIn(false);
          return navigate("/auth/login");
        }
      } else {
        return Promise.reject(error);
      }
    }
  );

  const context: IAuthContext = {
    api: api,
    login,
    isLoggedIn,
    loading,
    setLoading,
    logout,
    userData,
    rpOpen,
    setRpOpen,
  };

  return (
    <AuthContext.Provider value={context}>
      {children}
      <ResetPasswordDialog open={rpOpen} onClose={handleRpDialogClose} />
    </AuthContext.Provider>
  );
};
