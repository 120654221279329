import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/auth.context";
import { ContractsService, IContract } from "../services/contracts.service";
import { Container } from "../atoms/container";
import { SelectedProvider } from "../contexts/select-contract";
import { ContractDataGrid } from "../organisms/contract-data-grid";

const ArchivedPropertiesPage = () => {
  const { api } = useContext(AuthContext);

  const [archivedContracts, setArchivedContracts] = useState<IContract[]>([]);

  useEffect(() => {
    const contractsService = new ContractsService(api);

    contractsService
      .getArchivedContracts()
      .then((contracts) => setArchivedContracts(contracts));
  });

  return (
    <Container>
      <div style={{ marginBottom: 20 }}>
        <h1>Archived properties</h1>
        <p>
          You can access and manage your archived properties on this page. If it
          looks like a property has been archived in error, please contact us
          for assistance.
        </p>
      </div>

      <SelectedProvider>
        <ContractDataGrid contracts={archivedContracts} archivedPage={true}/>
      </SelectedProvider>

    </Container>
  );
};

export default ArchivedPropertiesPage;
