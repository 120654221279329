import React from "react";
import styled from "@emotion/styled";
import { Button, CardContent, TextField, Typography } from "@mui/material";
import logoMark from "../images/logo-mark.png";
import { Box } from "@mui/system";
import { PasswordService } from "../services/password.service";
import { AuthContext } from "../contexts/auth.context";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const ForgotPassword: React.FC = () => {
  const [email, setEmail] = React.useState<string | null>("");
  const { api } = React.useContext(AuthContext);

  const passwordService = new PasswordService(api);
  const navigate = useNavigate();

  const handleSubmitForgotPassword = (e: any) => {
    e.preventDefault();

    if (email) {
      passwordService
        .forgotPasswordRequest(email)
        .then(() => {
          toast.success("Password reset email sent");
          navigate("/auth/login");
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
    return;
  };

  return (
    <PageWrapper>
      <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
        <CardContent>
          <img src={logoMark} height={50} alt="" />
          <Typography variant="h6">Reset your password</Typography>
          <Typography variant="body1">
            {" "}
            Enter your email address below and we'll send you a link to reset
            your password.
          </Typography>

          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={handleSubmitForgotPassword}
          >
            Reset my password
          </Button>
        </CardContent>
      </Box>
    </PageWrapper>
  );
};
