import { Avatar, Card, Typography } from "@mui/material";
import React, { useContext } from "react";
import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "../contexts/auth.context";

const UserCardWrapper = styled(Card)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  box-shadow: none;
`;

const UserAvatar = styled(Avatar)`
  margin-right: 1rem;
`;

export const UserCard = () => {
  const { userData } = useContext(AuthContext);

  return (
    <UserCardWrapper>
      <UserAvatar>
        <FontAwesomeIcon icon={faUser} />
      </UserAvatar>
      <Typography variant="h6">
        {userData?.firstName} {userData?.lastName}{" "}
      </Typography>
    </UserCardWrapper>
  );
};
