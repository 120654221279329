import {
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  Typography,
  Grid,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../contexts/auth.context";
import { PasswordService } from "../services/password.service";
import { toast } from "react-toastify";

interface ContractDialogProperties {
  open: boolean;
  onClose: () => void;
}



export const ResetPasswordDialog = (props: ContractDialogProperties) => {
  const { onClose, open } = props;
  const { api, userData } = useContext(AuthContext);

  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [error, setError] = React.useState<String | null>(null);

  const resetPassword = async () => {
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      toast.error("Passwords do not match");
      return;
    }

    const passwordService = new PasswordService(api);

    if (userData) {
      passwordService
        .resetPassword(userData.email, password)
        .then(() => {
          toast.success("Password reset successfully");
          onClose();
        })
        .catch((err) => {
          setError("Error resetting password");
          toast.error("Something went wrong.");
        });
    }
  };

  useEffect(() => {
    if (password !== confirmPassword && password !== "") {
      setError("Passwords do not match");
    } else {
      setError(null);
    }
  }, [password, confirmPassword]);

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth={true}>
      <Box>
        <DialogTitle variant="h4" display="flex">
          Choose a new password
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Welcome to your new Safer Track account, we recommend you change
                your password to something you can remember.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                id="password"
                autoComplete="new-password"
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Confirm password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                id="Confirm-password"
                autoComplete="new-password"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={error !== null}
                onClick={resetPassword}
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  );
};
