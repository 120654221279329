import { AxiosInstance } from "axios";

export class PasswordService {
  constructor(private api: AxiosInstance) {}

  async forgotPasswordRequest(email: string) {
    return new Promise((resolve, reject) => {
      this.api
        .post("/auth/forgot-password", { email })
        .then((response) => resolve(true))
        .catch((error) => reject(error));
    });
  }

  async resetPassword(email: string, password: string) {
    return new Promise((resolve, reject) => {
      this.api
        .post("/auth/password-reset", { email, password })
        .then((response) => resolve(true))
        .catch((error) => reject(error));
    });
  }



}
