import React from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth.context";

export const ProtectedPage = ({ children }: any) => {
  const { isLoggedIn } = React.useContext(AuthContext);

  if (!isLoggedIn) {
    return <Navigate to="/auth/login" />;
  }

  return children;
};
