import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useContext } from "react";
import { AuthContext } from "../contexts/auth.context";
import { IRoute, navigation } from "../navigation/navigation";
import { NavLink, useLocation } from "react-router-dom";

import logoMark from "../images/logo-mark.png";
import { UserCard } from "../organisms/user-card";
import styled from "@emotion/styled";
import { Menu, IconButton, MenuItem, Divider } from "@mui/material";
import { Colors } from "../theme/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import pkg from "../../package.json";

const UserCardWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const Spacer = styled(Box)`
  flex: 1;
`;

const drawerWidth = 250;

const NavLinkItem = ({ item }: { item: IRoute }) => {
  const [active, setActive] = React.useState(false);

  const path = useLocation();

  React.useEffect(() => {
    if (path.pathname === item.path) {
      return setActive(true);
    }

    setActive(false);
  }, [path, item.path]);

  return (
    <ListItem disablePadding>
      <ListItemButton component={NavLink} to={item.path} selected={active}>
        <>
          <ListItemIcon
            style={{
              color: active ? Colors.primary : "inherit",
            }}
          >
            {item.icon}
          </ListItemIcon>
          <ListItemText>
            <Typography
              variant="body1"
              color={active ? Colors.primary : "inherit"}
              fontWeight={500}
            >
              {item.name}
            </Typography>
          </ListItemText>
        </>
      </ListItemButton>
    </ListItem>
  );
};

export default function Layout({ children }: any) {
  const { isLoggedIn, logout, setRpOpen } = useContext(AuthContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!isLoggedIn) {
    return <>{children}</>;
  }

  const version = pkg.version;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar>
          <img
            src={logoMark}
            alt="logo"
            style={{ height: 40, marginRight: 10 }}
          />
          <Typography variant="h6" noWrap>
            Safer-Track
          </Typography>

          <Spacer />

          <IconButton onClick={handleClick}>
            <FontAwesomeIcon color={"white"} icon={faEllipsisVertical} />
          </IconButton>

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <MenuItem onClick={() => setRpOpen(true)}>Change Password</MenuItem>
            <MenuItem onClick={logout}>Logout</MenuItem>
            <Divider />
            <MenuItem style={{fontSize: 9}}>v{version}</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <UserCardWrapper>
          <UserCard />
        </UserCardWrapper>

        <List>
          {navigation.map((item: IRoute, index) => (
            <NavLinkItem key={index} item={item} />
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 0 }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}
