import { Chip } from "@mui/material";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export const ValidChip = () => {
  return (
    <Chip
      sx={{
        display: "flex",
        alignItems: "center",
        width: "70%",
        justifyContent: "space-evenly",
      }}
      icon={<FontAwesomeIcon icon={faCheckCircle} size="lg" />}
      label="Valid"
      color="success"
    />
  );
};
